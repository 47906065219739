<template lang="pug">
  v-card.elevation-0
    v-form(ref="formParent" lazy-validation)
      v-layout(row wrap)
        v-flex(xs12 v-if="isSentByChild")
          v-checkbox.ma-0(
            v-model="certificate.selfDeclarer"
            label="Заявление подается ребенком достигшим возраста 14-ти лет самостоятельно"
            value="1"
            true-value="1"
            false-value="0"
          )
        template(v-if="!Number(certificate.selfDeclarer)")
          v-flex(xs12 md6)
            v-text-field(
              v-model="certificate.surParentName"
              label="Фамилия заявителя"
              name="surParentName"
              :rules="[$rules.required]"
            )
          v-flex(xs12 md6)
            v-text-field(
              v-model="certificate.firstParentName"
              label="Имя заявителя"
              name="firstParentName"
              :rules="[$rules.required]"
            )
          v-flex(xs12 md6)
            v-text-field(
              v-model="certificate.lastParentName"
              label="Отчество заявителя"
              name="lastParentName"
              :rules="[$rules.required]"
            )
          v-flex(xs12)
            v-textarea(
              v-model="certificate.contactsParent"
              label="Контактная информация заявителя"
              auto-grow
              rows="2"
              :rules="[$rules.required]"
            )
      v-btn.mr-2(color="primary" @click="next()" depressed) Далее
      v-btn(text @click="$emit('prev')") Назад

</template>

<script>
import moment from 'moment';

export default {
  name: 'ParentData',
  props: {
    certificate: { type: Object, required: true },
    errors: { type: Object, required: false, default: () => ({}) },
  },
  computed: {
    isSentByChild() {
      if (!this.certificate.birthChild) return false;
      const now = moment();
      const birthChild = moment(this.certificate.birthChild);
      return now.diff(birthChild, 'years', true) > 14;
    },
  },
  methods: {
    next() {
      if (this.$refs.formParent.validate()) {
        this.$emit('next');
      }
    },
  },
};
</script>
