<template lang="pug">
  v-card.elevation-0
    v-tabs(grow)
      v-tab(ripple) Выбрать на карте
      v-tab-item
        svg-map.mt-3(
          return-link
          @select-region="selectRegion"
        )
      v-tab(ripple) Выбрать из списка
      v-tab-item
        v-select.my-3.pt-0(
          label="Регион"
          v-model="selectedRegion"
          :items="regions"
          item-text="name"
          return-object
        )
    v-progress-linear(:indeterminate="true" height="3" v-show="loading")
    v-alert(:value="selectedRegion && !municipalities.length && !loading" type="error" outlined)
      |  В данном регионе муниципалитеты отсутствуют
    v-btn.ml-0(v-if="selectedRegion && municipalities.length" color="primary" @click="$emit('next')" depressed) Далее
</template>

<script>
import MainService from '@/services/main';

import SvgMap from '@/components/SvgMap';

export default {
  name: 'RegionData',
  components: { SvgMap },
  props: {
    municipalities: { type: Array, required: false, default: () => ([]) },
    loading: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      selectedRegion: null,
      regions: [],
    };
  },
  watch: {
    selectedRegion(val) {
      this.$emit('select-region', val);
    },
  },
  created() {
    this.getRegions();
  },
  methods: {
    // TODO получить регион янао
    getRegions() {
      this.$rHandler(
        MainService.getRegions({ 'per-page': 50 }),
        ({ data }) => { this.regions = data.data; },
      );
    },
    selectRegion(region) {
      [this.selectedRegion] = this.regions.filter((item) => item.url === region);
    },
  },
};
</script>

<style scoped>

</style>
