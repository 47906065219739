/*
 * скачивание PDF документа
 * @param pdf {String} - base64
 * @param name {String} - название файла
 */
export default function downloadPDF(pdf = '', name = 'spravka') {
  pdf = pdf.toString().replace(/.+base64,/, '');
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = `${name}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.setAttribute('target', '_blank');
  downloadLink.click();
}
