<template lang="pug">
  v-card
    v-form(ref="formChild" lazy-validation)
      v-layout(row wrap v-if="mun && mun.groups")
        v-flex(xs12 md6)
          v-text-field(
            v-model="certificate.surChildName"
            label="Фамилия ребенка"
            name="surChildName"
            :rules="[$rules.required]"
          )
        v-flex(xs12 md6)
          v-text-field(
            v-model="certificate.firstChildName"
            label="Имя ребенка"
            name="firstChildName"
            :rules="[$rules.required]"
          )
        v-flex(xs12 md6)
          v-text-field(
            v-model="certificate.lastChildName"
            label="Отчество ребенка"
            name="lastChildName"
            :rules="[$rules.required]"
          )
        v-flex(xs12 md6)
          date-picker(
            v-model="certificate.birthChild"
            :rules="[$rules.required]"
            label="Дата рождения ребенка"
          )
        v-flex(xs12)
          v-select(
            label="Тип документа ребенка"
            v-model="certificate.docType"
            :items="documentTypes"
            item-value="id"
            item-text="text"
            :rules="[$rules.required]"
          )
        template(v-if="certificate.docType === 1 || certificate.docType === 5")
          v-flex(xs12 md6)
            v-text-field(
              v-model="certificate.series"
              label="Серия документа ребенка"
              :placeholder="seriesFieldAttributes.placeholder"
              :rules="seriesFieldAttributes.rules"
            )
              template(v-slot:append)
                v-icon(@click="showHelpDoc = !showHelpDoc") help
            v-expand-transition
              div(v-if="showHelpDoc")
                | Для&nbsp;
                b свидетельства о рождении:&nbsp;
                | обязательно наличие тире "-".
                | Перед тире римские цифры написанные Английскими заглавными буквами I(i),V или X.
                | После тире двузначный буквенный код, написанный заглавными Русскими буквами.
          v-flex(xs12 md6)
            v-text-field(
              v-model="certificate.number"
              mask="######"
              label="Номер документа ребенка"
              :rules="[$rules.required, $rules.passportNumber]"
              placeholder="999999"
            )
        template(v-else-if="certificate.docType === 10")
          v-flex(xs12)
            v-text-field(
              v-model="certificate.anotherDoc"
              :rules="[$rules.required]"
              label="Документ"
            )

        v-flex(xs12)
          v-select(
            label="Группа сертификата"
            v-model="certificate.certGroupId"
            :items="mun.groups || []"
            :rules="[$rules.required]"
            item-value="id"
            item-text="name"
          )
        v-flex(xs12)
          v-text-field(
            v-model="certificate.addressChild"
            label="Адрес регистрации (пребывания) ребенка на территории муниципалитета"
            name="address"
            :rules="[$rules.required]"
          )
    v-layout(row wrap)
      v-flex(xs12 md6)
        v-text-field(
          v-model="certificate.email",
          :rules="[$rules.email]",
          label="Адрес электронной почты",
        )
      v-flex(xs12 md6)
        v-text-field(
          v-model="certificate.email_confirm"
          :rules="[$rules.email, rules.email_confirm]"
          label="Подтверждение почты"
          @keydown.ctrl.86.prevent
          @keydown.meta.86.prevent
          @click.right.prevent
        )

      v-btn.ml-0(color="primary" @click="next()" depressed) Далее
      v-btn.ml-0(text @click="$emit('prev')") Назад

</template>

<script>
import DatePicker from '@/components/DatePicker';

// типы документов на АИС Реестр
const documentTypes = [
  { id: 1, text: 'Паспорт' },
  { id: 5, text: 'Свидетельство о рождении' },
  { id: 10, text: 'Документ иностранного образца' },
];

export default {
  name: 'ChildData',
  components: { DatePicker },
  props: {
    certificate: { type: Object, required: true },
    errors:      { type: Object, required: false, default: () => ({}) },
    mun:         { type: Object, required: false, default: null },
  },
  data() {
    return {
      rules: {
        email_confirm: (v) => v === this.certificate.email || 'Email должен совпадать',
      },
      showHelpDoc: false,
      documentTypes,
    };
  },
  computed: {
    seriesFieldAttributes() {
      const passport = {
        placeholder: 'Серия паспорта',
        mask: '####',
        rules: [this.$rules.required, this.$rules.passportSeries],
      };
      const birthCertificate = {
        placeholder: 'IX-АД; IV-БА; 5286;',
        // mask: 'XXXXXXXXXXXX',
        rules: [this.$rules.required],
      };
      return this.certificate.docType === 1 ? passport : birthCertificate;
    },
  },
  methods: {
    next() {
      if (this.$refs.formChild.validate()) {
        this.$emit('next');
      }
    },
  },
};
</script>
