<template lang="pug">
  v-container(grid-list-md)
    h1.section-title Получить сертификат
    v-stepper(v-model="stepper" vertical)
      v-stepper-step.cursor-pointer(
        :complete="stepper > 1"
        step="1"
        @click="stepper = 1"
      ) Выберите свой регион
      v-stepper-content(step="1")
        region-data(
          :municipalities="municipalities"
          :loading="loading"
          @select-region="getMunsAndGroups"
          @next="stepper = 2"
        )

      v-stepper-step(
        :class="{ 'cursor-pointer': stepper > 2 }"
        :complete="stepper > 2"
        step="2"
        @click="stepper > 2 ? stepper = 2 : null"
      ) Выберите муниципалитет
      v-stepper-content(step="2")
        v-card.elevation-0
          v-select(
            label="Муниципалитет"
            :items="municipalities"
            item-text="name"
            v-model="selectedMun"
            return-object
          )
          v-alert(:value="selectedMun && !selectedMun.groups" type="error" outlined)
            |  К сожалению, выбранный Вами муниципалитет не начал выдачу сертификатов дополнительного образования.
            br
            |  Информацию о сроках старта кампании по выдаче сертификатов можно уточнить по телефонам органа местного
            |  самоуправления, осуществляющего управление в сфере образования.

          v-btn.mr-2(v-if="selectedMun && selectedMun.groups" color="primary" @click="stepper = 3" depressed) Далее
          v-btn(text @click="stepper = 1") Назад

      v-stepper-step(
        :class="{ 'cursor-pointer': stepper > 3 }"
        :complete="stepper > 3"
        step="3"
        @click="stepper > 3 ? stepper = 3 : null"
      ) Данные ребенка
      v-stepper-content(step="3")
        child-data(
          :certificate="certificate"
          :mun="selectedMun"
          @next="stepper = 4"
          @prev="stepper = 2"
        )

      v-stepper-step(
        :class="{ 'cursor-pointer': stepper > 4 }"
        :complete="stepper > 4"
        step="4"
        @click="stepper > 4 ? stepper = 4 : null"
      ) Данные заявителя
      v-stepper-content(step="4")
        parent-data(
          :certificate="certificate"
          @next="stepper = 5"
          @prev="stepper = 3"
        )

      v-stepper-step(:complete="stepper > 5" step="5") Соглашения
      v-stepper-content(step="5")
        agreements-data(
          :certificate="certificate"
          :mun="selectedMun"
          @submit="createCertificate()"
          @prev="stepper = 4"
        )

</template>

<script>
import axios from 'axios';
import moment from 'moment';

import ChildData from './ChildData';
import ParentData from './ParentData';
import AgreementsData from './AgreementsData';
import RegionData from './RegionData';

import MainService from '@/services/main';
import AisRegistryService from '@/services/ais-reestrs-service';

import downloadPDF from '@/utils/downloadPDF';

export default {
  components: {
    RegionData,
    AgreementsData,
    ParentData,
    ChildData,
  },
  data() {
    return {
      stepper: 1,
      selectedRegion: null,
      municipalities: [],
      selectedMun: null,
      certificate: {
        munId: null,
        surChildName: '',
        firstChildName: '',
        lastChildName: '',
        birthChild: null,
        addressChild: '',
        certGroupId: null,
        email: '',
        email_confirm: '',
        docType: null,
        series: '',
        number: '',
        anotherDoc: '',
        selfDeclarer: '0',
        surParentName: '',
        firstParentName: '',
        lastParentName: '',
        contactsParent: '',
        token: '',
        word: '',
        agree: '',
        acquainted: '',
        termsOfUse: '',
      },
      loading: false,
    };
  },
  methods: {
    /**
     * Получение ссылку на реестр региона
     * и по ссылке получаем ее муниципалитеты
     * @param region - регион
     */
    async getMunsAndGroups(region = this.selectedRegion) {
      if (!region) return false;

      this.loading = true;
      let link = null;
      await this.$rHandler(
        MainService.getCertificateWays(region.id),
        ({ data }) => { [{ link }] = data.data; },
      ).finally((data) => data);

      if (link) {
        const config = {
          headers: {
            'client_id': '54w6hgdbj6yhvadku6zb6kn7gy4ygutf',
            'client_secret': 'KBG8LkjMdqKvfhDfsmST7X6T',
          },
        };

        const url = new URL(link);
        await axios.get(`${url.origin}/api/v1/mun-groups`, config)
          .then(({ data }) => {
            if (data.result_code !== 'FLSCS') {
              this.$toasted.error(data.result_message, { icon: 'error', duration: 3000 });
            } else {
              this.municipalities = data.data;
            }
          }).finally((data) => data);
      }
      this.loading = false;
      return true;
    },
    /**
     * Создание сертификата
     * Все данные должны быть строчными
     */
    createCertificate() {
      const certificate = { ...this.certificate };
      if (certificate.docType === 10) {
        certificate.number = '';
        certificate.series = '';
      } else {
        certificate.anotherDoc = '';
      }
      certificate.birthChild = moment(certificate.birthChild).format('DD.MM.YYYY');
      certificate.docType = (certificate.docType || '').toString();
      certificate.certGroupId = (certificate.certGroupId || '').toString();
      certificate.munId = this.selectedMun.id;

      this.$rHandler(
        AisRegistryService.createCertificate(certificate),
        ({ data }) => {
          if (data.pdf_certificate) downloadPDF(data.pdf_certificate, 'сертификат');
          if (data.pdf_statement) downloadPDF(data.pdf_statement, 'заявление');
          this.$toasted.success('Заявка успешно отправленна на рассмотрение', { duration: 3000 });
          this.$router.replace({ path: '/' });
        },
        ({ data }) => {
          if (data.result_code === 'VAL01') {
            data.errors.validation.forEach((value, i) => {
              this.$toasted.error(value, { duration: 5000 + 1000 * i, icon: 'error' });
            });
            return data;
          }
          return undefined;
        },
      ).finally(() => { this.$root.$emit('refreshCaptcha'); });
    },
  },
};
</script>
