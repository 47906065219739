<template lang="pug">
  v-card.elevation-0
    v-form(ref="formAgree" lazy-validation)
      v-checkbox(
        v-model="certificate.agree"
        color="primary"
        name="agree"
        value="on"
        :rules="[$rules.required]"
      )
        template(v-slot:label)
          a.primary--text(@click.stop="downloadAccessFile('agreement')") Даю Согласие на обработку персональных данных
            |  в порядке, установленном Федеральным законом от 27 июля 2006 г. №152-ФЗ «О персональных данных».

      v-checkbox(
        v-model="certificate.acquainted"
        color="primary"
        name="acquainted"
        value="on"
        :rules="[$rules.required]"
      )
        template(v-slot:label)
          span Ознакомлен с
            a.primary--text.ml-1(@click.stop="downloadAccessFile('rules')") условиями Правил
              |  персонифицированного финансирования дополнительного образования детей
            |  и
            a.primary--text.ml-1(@click.stop="downloadAccessFile('regulation')") муниципальным Положением
              |  о персонифицированном дополнительном образовании

      v-checkbox(
        v-model="certificate.termsOfUse"
        color="primary"
        name="termsOfUse"
        value="on"
        :rules="[$rules.required]"
      )
        template(v-slot:label)
          a.primary--text(@click.stop="downloadAccessFile('license')")
            |  Ознакомлен с Пользовательским соглашением АИС Реестр сертификатов

    v-progress-linear(:indeterminate="true" height="3" v-show="loading")
    v-flex(xs12 sm8 md6 lg4)
      captcha-input(
        v-model="certificate.word"
        name="captcha"
        ais-registry
        @token="certificate.token = $event"
      )
    v-btn.mr-2(color="primary" @click="submit" depressed) Отправить заявку
    v-btn(text @click="$emit('prev')") Назад

</template>

<script>
import AisRegistryService from '@/services/ais-reestrs-service';

import CaptchaInput from '@/components/inputs/CaptchaInput';

import downloadPDF from '@/utils/downloadPDF';

export default {
  name: 'AgreementsData',
  components: { CaptchaInput },
  props: {
    certificate: { type: Object, required: true },
    mun: { type: Object, required: false, default: null },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    submit() {
      if (this.$refs.formAgree.validate()) {
        this.$emit('submit');
      }
    },
    downloadAccessFile(name) {
      this.loading = true;
      let API;
      switch (name) {
        case 'license':
          API = 'getLicensePDF';
          break;
        case 'rules':
          API = 'getRulesPDF';
          break;
        case 'regulation':
          API = 'getRegulationPDF';
          break;
        case 'agreement':
          API = 'getAgreementPDF';
          break;
        default:
          API = 'getLicensePDF';
      }

      this.$rHandler(
        AisRegistryService[API](this.mun.id),
        ({ data }) => { downloadPDF(data.data.pdf, name); },
      ).finally(() => { this.loading = false; });
    },
  },
};
</script>
